html {
  overscroll-behavior-y: none;
}
body {
  padding: 0;
  padding-right: 0 !important;
  margin: 0;
  z-index: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  overscroll-behavior-y: none;
}
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background-color: #f5f6fa;
}

body::-webkit-scrollbar-thumb {
  background-color: #d7dbec;
}

input:disabled {
  -webkit-appearance: none;
  opacity: 1;
}
